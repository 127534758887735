<template>
    <b-overlay :show="showLoader">
        <b-row v-if="ad && !ad.approved" class="w-100 print-none">
            <b-col class="w-100">
                <b-alert variant="danger" class="p-1 text-center w-100" show><b>Ta mali oglas ni potrjen! Vidijo ga lahko samo administratorji, navadni uporabniki do njega nimajo dostopa!</b></b-alert>
            </b-col>
        </b-row>

        <blue-background class="print-none"/>
        <div v-if="ad">
            <b-row class="pt-1">
                <b-col cols="12" md="6" class="">
                    <div class="h-100">
                        <b-img v-if="ad.main_photo !== ''" class="main-photo" :src="`/api/user/v1/image/${ad.main_photo}`" fluid/>
                        <b-img v-else class="main-photo" :src="require('@/assets/images/esoseska/no-image.jpg')" fluid/>
                    </div>
                </b-col>
                <b-col cols="12" md="6" class="mt-2 mt-md-0 d-flex flex-column">
                    <h3 class="title">{{ ad.title }}</h3>
                    <div class="mt-1 desc mb-2 h-100 d-flex flex-column">
                        <span class="c2c-description html-display" v-html="ad.content"/>
                        <div class="mt-auto d-flex align-items-center justify-content-between">
                            <a @click="goto('description')">> preberi več</a>
                            <div class="date">objavljen: {{dayjs(ad.added_date).format("DD.MM.YYYY")}}</div>
                        </div>
                    </div>
                    <div class="mt-auto">
                        <div class="p-1 d-flex flex-column h-100" style="background: #F3F3F3; -webkit-print-color-adjust: exact;">
                            <h2 style="font-weight: 400;font-size: 20px;line-height: 23px;">Cena:</h2>
                            <div class="mt-auto" v-if="ad.listing_type !== 4">
                                <h1 v-if="ad.price_offer" class="mb-0 price">PO DOGOVORU</h1>
                                <h1 v-else-if="ad.price_offer===undefined && ad.price===0" class="mb-0 price">PO DOGOVORU</h1>
                                <h1 v-else class="mb-0 price">{{$convertPriceIntegerToFloat(ad.price)}} €</h1>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-1">
                <b-col cols="12" md="6" class="screen-photos">
                    <div v-if="photos && photos.length > 0">
                        <swiper :swiper-data="photos" :pagination="false" type="imageGallery"/>
                    </div>
                </b-col>
            </b-row>
            <div v-if="ad" id="description" class="mt-2">
                <div class="c2c-description-all html-display" v-html="ad.content"/>
            </div>
            <div v-if="ad.additional_photos.length > 0" class="mt-2 mobile-photos">
                <div class="mx-1" v-for="(item, index) of ad.additional_photos" :key="'photo_'+ index">
                    <b-img v-if="item !== ad.main_photo" style="object-fit: cover;" fluid :src="'/api/user/v1/image/' + item"/>
                </div>
            </div>
            <div class="mt-2 p-1" style="background: #72A5D8; -webkit-print-color-adjust: exact;">
                <div class="text-white" style="font-style: normal;font-weight: 700;font-size: 20px;line-height: 18px;">Kontaktirajte prodajalca</div>
            </div>
            <div class="py-4 px-2 px-md-4 contact-data text-center w-100" style="background: #F3F3F3; -webkit-print-color-adjust: exact;">
                <div v-if="user">Ime: <span style="font-weight: bold;">{{ user.name }} {{ user.surname }}</span></div>
                <div class="mt-2">Regija: <span style="font-weight: bold;">{{ ad.region || 'Ni podane regije' }}</span></div>
                <div>Kraj: <span style="font-weight: bold;">{{ ad.town || 'Ni podane lokacije' }}</span></div>
                <div v-if="user" class="mt-2">Telefon: <a style="font-weight: bold; color: black !important;" :href="'tel:' + user.telephone_number">{{ user.telephone_number || '/' }}</a></div>
                <div v-if="user">Email: <a style="font-weight: bold; color: black !important;"  :href="'mailto:' + user.email">{{ user.email || '/' }}</a></div>
                <div class="mt-4 d-flex justify-content-center print-btn">
                    <rounded-button padding="8px 20px" :icon="true" :block="true" style="width: 200px" @click="onPrintClick">
                        <template v-slot:name>Natisni oglas</template>
                        <template v-slot:icon><img :src="require('@/assets/svg/print.svg')" alt="Print"/></template>
                    </rounded-button>
                </div>
            </div>
        </div>
    </b-overlay>
</template>

<script>
    import Swiper from '../../Components/Swiper'
    import {BRow, BCol, BImg, BOverlay, BAlert} from 'bootstrap-vue'
    import RoundedButton from '@/views/Components/RoundedButton'
    import BlueBackground from '@/views/Components/BlueBackground'
    export default {
        components: {
            BlueBackground,
            RoundedButton,
            Swiper,
            BRow,
            BCol,
            BImg,
            BOverlay,
            BAlert
        },
        data() {
            return {
                categories: null,
                showLoader: false,
                ad: null,
                user: null,
                photos: []
            }
        },
        methods: {
            selectImage(item) {
                this.ad.main_photo = item
            },
            setBreadcrumbTitle() {
                let main_category = this.categories.find(x => x.children.find(y => y.id === this.ad.category_id))
                let category = null

                //Ce izdelk ni podkategorija se preveri glavne kategorije
                if (!main_category) {
                    main_category = this.categories.find(y => y.id === this.ad.category_id)
                } else {
                    category = main_category.children.find(y => y.id === this.ad.category_id)
                }
                this.$route.meta.breadcrumb[1].text = main_category.title

                let index = 2
                if (this.$route.meta.breadcrumb.length > index && category) {
                    this.$route.meta.breadcrumb[index].text = category.title
                    this.$route.meta.breadcrumb[index].to = `/oglasi?category_id=${category.id}`
                    index++
                } else if (category) {
                    this.$route.meta.breadcrumb.push({ text: category.title, to: `/oglasi?category_id=${category.id}`})
                    index++
                }  
                
                if (this.$route.meta.breadcrumb.length > index) {
                    this.$route.meta.breadcrumb[index].text = this.ad.title
                    this.$route.meta.breadcrumb[index].to = `/oglasi/${this.ad.id}`
                    this.$route.meta.breadcrumb[index].active = true
                } else {
                    this.$route.meta.breadcrumb.push({ text: this.ad.title, to: '', active: true})
                }  
               
                this.$root.$emit('force-update-breadcrumbs')
            },
            async loadData() {
                const thisIns = this
                thisIns.showLoader = true
                this.photos = []

                this.$scrollToTop()
                try {
                    const adResponse = await thisIns.$http.get(`/api/user/v1/c2c/${this.$route.params.ad_id}`)
                    this.ad = adResponse.data
                    
                    for (const item of this.ad.additional_photos) {
                        this.photos.push({itemId: `${item}_idSlike`, src: `/api/user/v1/image/${  item}`, thumbnail: `/api/user/v1/image/thumbnail_${  item}`, thumbnailHeight: '100px'})
                    }

                    const categories = await thisIns.$http.get('/api/user/v1/c2c/category/')
                    this.categories = categories.data
                    this.user = this.ad.author
                    this.setBreadcrumbTitle(this.ad.title)
    
                } catch (error) {
                    thisIns.$printError(`Pri nalaganju oglasov je prišlo do napake!\n${error.message}`)

                } finally {
                    thisIns.showLoader = false
                }
            },
            stripHTML(val) {
                if (!val) return ''
                const text = val.replace(/<(?!br\s*\/?)[^>]+>/g, '')
                if (text.length > 300) return `${text.substring(0, 300)}...<br>`
                else return `${text}<br>`
            },
            goto(id) {
                const element = document.getElementById(id)
                element.scrollIntoView({ behavior: 'smooth', block: 'center' })
            },
            onPrintClick() {
                window.print()
            }
        },
        async created() {
            await this.loadData()
        },
        beforeRouteLeave(to, from, next) {
            // before leaving news page, reset breadcrumb title to empty
            this.setBreadcrumbTitle('')
            next()
        }
    }
</script>

<style scoped>
a {
  font-weight: 700;
  font-size: 17px;
  line-height: 21px;
  color: #72A5D8 !important;
}
.price {
  font-weight: 700;
  font-size: 40px;
  line-height: 50px;
  text-transform: uppercase;
}
.contact-data {
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 24px;
}
.title {
  font-weight: 700;
  font-size: 38px;
  line-height: 48px;
}
.main-photo {
  max-height: 500px !important;
}
.mobile-photos {
  display: none;
}
.c2c-description{
  display: -webkit-box;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.date {
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #72A5D8;
}
@media only screen and (max-width: 500px) {
  .price{
    font-size: 25px !important;
  }
}
@media print {
  .desc, .print-btn, .print-none {
    display: none !important;
  }
  .screen-photos {
    display: none !important;
  }
  .mobile-photos {
    display: flex;
    flex-wrap: wrap;
  }
  .mobile-photos img {
    width: 300px !important;
    height: auto !important;
    margin-bottom: 10px;
  }
}
</style>
<style>
.c2c-description-all ul {
  padding-left: 20px !important;
}
</style>